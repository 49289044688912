import exp from 'constants';
export const WS_URL = 'wss://grex.co/ws/';

export const API_CLIENT_BASE = '/api/1/rec-server/client';

export const API_Login = API_CLIENT_BASE + '/login/user';
export const API_Login_Registrar = API_CLIENT_BASE + '/login/registrar';
export const API_Sync_Kyc = API_CLIENT_BASE + '/kyc/sync';
export const API_Registrar_SEND_VERIFY_EMAIL = API_CLIENT_BASE + '/email/registrar/resend';


export const API_CAPTCHA = API_CLIENT_BASE + '/captcha';
export const API_Account_Check = API_CLIENT_BASE + '/login/secret';
export const API_Register = API_CLIENT_BASE + '/user';
export const API_VERIFY_EMAIL = API_CLIENT_BASE + '/email/verify';
export const API_VERIFY_FORGET_EMAIL = API_CLIENT_BASE + '/email/forget/verify';
export const API_VERIFY_EMAIL_RESEND = API_CLIENT_BASE +'/email/resend';
export const API_VERIFY_EMAIL_SEND = API_CLIENT_BASE +'/email/send';
export const API_UserInfo = API_CLIENT_BASE + '/user/info';
export const API_UPDATE_WALLET_ADDRESS = API_CLIENT_BASE + '/user/wallet';
export const API_Upload = API_CLIENT_BASE + '/kyc/upload';
export const API_Submit = API_CLIENT_BASE + '/user/kyc/submit';
export const API_Review = API_CLIENT_BASE + '/user/kyc/review';
export const API_CHECK_LIANLIAN_ID = API_CLIENT_BASE + '/user/lianlian';
export const API_Verify2StepCode = API_CLIENT_BASE + '/login/totp';
export const API_SubUsers = API_CLIENT_BASE + '/users/sub';
export const API_SubUser_Create = API_CLIENT_BASE + '/user/sub';
export const API_NFT_MINT_REQUEST = API_CLIENT_BASE + '/nft/mint/request';
export const API_NFT_MINT_REQUEST_CANCEL = API_CLIENT_BASE + '/nft/mint/request/cancel/';
export const API_NFT_MINT = API_CLIENT_BASE + '/nft/mint';
export const API_NFT_MY_OFFER = API_CLIENT_BASE + '/nft/my/offer';
export const API_NFT_OFFER_ACCEPT = API_CLIENT_BASE + '/nft/offer/accept';
export const API_NFT_OFFER_PRE_ACCEPT = API_CLIENT_BASE + '/nft/offer/pre/accept';
export const API_NFT_OFFER_REJECT = API_CLIENT_BASE + '/nft/offer/reject';
export const API_NFT_MY_OFFER_CANCEL =
  API_CLIENT_BASE + '/nft/my/offer/cancel/';
export const API_NFT_ORDER_BUY = API_CLIENT_BASE + '/nft/order/buy';
export const API_NFT_OFFER_BUY = API_CLIENT_BASE + '/nft/offer/buy';
export const API_NFT_OFFER_BUY_SIGN = API_CLIENT_BASE + '/nft/offer/buy/sign';
export const API_NFT_BUY_SIGN = API_CLIENT_BASE + '/nft/buy/sign';
export const API_NFT_UNIQUE_SALE_SIGN = API_CLIENT_BASE + '/nft/unique/sale/sign'
export const API_NFT_UNIQUE_SALE_CAN_PAY = API_CLIENT_BASE + '/nft/unique/sale/can/pay/'
export const API_NFT_MY_ORDER = API_CLIENT_BASE + '/nft/my/order';
export const API_NFT_MY_ORDER_CANCEL =
  API_CLIENT_BASE + '/nft/my/order/cancel/';
export const API_NFT_MY_PRE_ORDER = API_CLIENT_BASE + '/nft/my/pre/order';
export const API_NFT_ORDER_DETAIL =
  API_CLIENT_BASE + '/nft/order/detail/';
export const API_NFT_ORDER_VIEW = API_CLIENT_BASE + '/nft/order/view/';
export const API_NFT_ORDER_CHECK_SELL = API_CLIENT_BASE + '/nft/order/check';
export const API_NFT_MY_LIST_SEARCH = API_CLIENT_BASE + '/nft/my/search';
export const API_NFT_USER_SEARCH = API_CLIENT_BASE + '/nft/user/search';
export const API_NFT_SELF_UER_SEARCH = API_CLIENT_BASE + '/nft/self/search';
export const API_NFT_USER_DETAIL = API_CLIENT_BASE + '/nft/user/detail/'
export const API_NFT_OWN_DETAIL = API_CLIENT_BASE + '/nft/own/detail/';
export const API_NFT_MY_OWN_FROM_NFT_ID = API_CLIENT_BASE + '/nft/my/own/from';
export const API_NFT_MY_SALE = API_CLIENT_BASE + '/nft/my/sale/';
export const API_NFT_MY_SALE_CALLBACK = API_CLIENT_BASE + '/nft/my/sale/callback/';
export const API_GET_SALE_BY_ORDER_ID = API_CLIENT_BASE + '/nft/sale/by/order/';
export const API_GET_SALE_BY_NFT_ID = API_CLIENT_BASE + '/nft/sale/by/nft/';
export const API_TOP_NFT_ORDERS = API_CLIENT_BASE + '/nft/top';
export const API_NFT_MY_SALE_SEARCH =
  API_CLIENT_BASE + '/nft/my/sale/search';
export const API_NFT_MY_SALE_PAYMENT =
  API_CLIENT_BASE + '/nft/my/sale/payment/';
export const API_CREATE_REC_TRANSFER =
  API_CLIENT_BASE + '/nft/rec_transfer';
export const API_SERACH_REC_TRANSFER =
  API_CLIENT_BASE + '/nft/rec_transfer/search';
export const API_NFT_MARKET_SEARCH =
  API_CLIENT_BASE + '/nft/market/search';
export const API_NFT_MARKET_CHART_SEARCH = API_CLIENT_BASE + '/nft/market/chart/search';
export const API_NFT_MINT_SEARCH = API_CLIENT_BASE + '/nft/mint/search';
export const API_NFT_DETAIL = API_CLIENT_BASE + '/nft/detail';
export const API_NFT_SIGN_MESSAGE = API_CLIENT_BASE + '/nft/sign/message';
export const API_DATA_DICTIONARY = API_CLIENT_BASE + '/util/dictionary';
export const API_IDS_COUNT = API_CLIENT_BASE + '/util/ids/';
export const API_GET_BANNER = API_CLIENT_BASE + '/util/banners';
export const API_NFT_RETIRE = API_CLIENT_BASE + '/nft/retire';
export const API_NFT_RETIRE_LIST = API_CLIENT_BASE + '/nft/retire/list'

export const API_SubUser_Delete = API_CLIENT_BASE + '/users/sub/';
export const API_SubUser_ChgPwd = API_CLIENT_BASE + '/user/sub/pwdchange';
export const API_User_ChgPwd = API_CLIENT_BASE + '/user/pwdchange';
export const API_User_Retrieve_PWD = API_CLIENT_BASE + '/user/forgetpwd';


export const API_UpdateProfile = API_CLIENT_BASE + '/fileUpload';
export const API_Blogs = API_CLIENT_BASE + '/blog/blogs';
export const API_TOP_BLOGS = API_CLIENT_BASE + '/blog/top/blogs/'
export const API_Blog_detail = API_CLIENT_BASE + '/blog';
export const API_BLOG_BY_ID = API_CLIENT_BASE + '/blog/id/';

export const API_FAQ = API_CLIENT_BASE + '/faq';

export const API_Image_Upload = API_CLIENT_BASE + '/kyc/upload';
export const API_FileInfo_Upload = API_CLIENT_BASE + '/fileUpload';
export const API_FileInfo_Get = API_CLIENT_BASE + '/fileInfo';
export const API_FILE_UPLOAD_STS = "/api/1/rec-server/client/upload/sts";

export const API_GET_USER_ASSET = API_CLIENT_BASE + '/rec/asset/by/user';
export const API_GET_USER_ASSET_BY_REGISTRY = API_CLIENT_BASE + '/rec/asset/by/user/'

export const API_New_Rec = API_CLIENT_BASE + '/rec';
export const API_Fetch_Recs = API_CLIENT_BASE + '/rec/recs';
export const API_TOP_RECS = API_CLIENT_BASE + '/rec/top/recs';
export const API_New_Offer = API_CLIENT_BASE + '/rec/offer';
export const API_Done_Offer = API_CLIENT_BASE + '/rec/offer/done';
export const API_Dismiss_Offer = API_CLIENT_BASE + '/rec/offer/dismiss';
export const API_Cancel_Offer = API_CLIENT_BASE + '/rec/offer/cancel';
export const API_Fetch_Rec_Offer = API_CLIENT_BASE + '/rec/offers/';
export const API_Rec_By_Id = API_CLIENT_BASE + '/rec/';
export const API_GET_REC_OFFER_BY_ID = API_CLIENT_BASE + '/rec/offer/';

export const API_My_Made_Offers = API_CLIENT_BASE + '/rec/recs/made'
export const API_My_Receive_Offers = API_CLIENT_BASE + '/rec/recs/received'
export const API_Made_Rec_Sold = API_CLIENT_BASE + '/rec/sold'
export const API_Rec_Cancel = API_CLIENT_BASE + '/rec/cancel'

export const API_Rec_Chat_Users =
  API_CLIENT_BASE + '/rec/offer/chat/pairs/';
export const API_Rec_Chat = API_CLIENT_BASE + '/rec/offer/chat';
export const API_Rec_Seller_Chats =
  API_CLIENT_BASE + '/rec/offer/chats/seller';
export const API_Rec_User_Chats =
  API_CLIENT_BASE + '/rec/offer/chats/user';
export const API_Conversion =
  API_CLIENT_BASE + '/rec/offer/chat/conversation';
export const API_Refresh_token = API_CLIENT_BASE + '/token/refresh'
export const API_SEACH_USER_TRANSACTIONS = API_CLIENT_BASE + '/user/transactions'


export const API_save_kyc = API_CLIENT_BASE + '/kyc'

export const API_MEMBERSHIP_PLAN_ACTIVE = API_CLIENT_BASE + '/membership/plan/active'
export const API_MEMBERSHIP_ORDER = API_CLIENT_BASE + '/membership/order'
export const API_MEMBERSHIP_CLIENT_ACTIVE = API_CLIENT_BASE + '/membership/client/active'
export const API_MEMEBERSHIP_CLIENT_ORDER = API_CLIENT_BASE + '/membership/client/order/'
export const API_MEMBERSHIP_CLIENT_ORDRES = API_CLIENT_BASE + '/membership/client/orders'
export const API_User_Conversion =
    API_CLIENT_BASE + '/rec/offer/conversations';
export const API_New_Notify = API_CLIENT_BASE + '/notify/'
export const API_Notification = API_CLIENT_BASE + '/notify/notifications'
export const API_Email_Exist = API_CLIENT_BASE + '/email/has'
export const API_GET_USER_KYC_STATUS = API_CLIENT_BASE + '/user/kyc/status'

export const API_New_Request = API_CLIENT_BASE + '/request/'
export const API_Fetch_Request = API_CLIENT_BASE + '/request/requests'
export const API_CONTACT_US = API_CLIENT_BASE + '/request/contact'

export const API_NFT_CAN_OFFER_AMOUNT = API_CLIENT_BASE + '/nft/canOfferAmount/'
export const API_NFT_CAN_LIST_AMOUNT = API_CLIENT_BASE + '/nft/canListAmount/'
export const API_NFT_CAN_SALE_AMOUNT = API_CLIENT_BASE + '/nft/canSaleAmount/'
export const API_NFT_OFFER_LAST_STAT = API_CLIENT_BASE + '/nft/offer/last/stat/'
export const API_NFT_ORDER_RELATED = API_CLIENT_BASE + '/nft/order/related'

export const API_USER_NEW_NOTIFICATION = API_CLIENT_BASE + '/notify/user/new/notifications'
export const API_USER_TRANSACTION_EXPORT = API_CLIENT_BASE + '/report/user/transactions'
export const API_LIANLIAN = API_CLIENT_BASE + '/user/lianlian'

export const API_LIANLIAN_PAY = API_CLIENT_BASE + '/lianlianpay'

export const API_PAY = API_CLIENT_BASE + '/pay'

export const API_CARBON_CREDIT = API_CLIENT_BASE + '/carbon'