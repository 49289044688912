<script lang="ts" setup>
import { useAccountStore } from '@/stores/modules/account';
import RecImage from './RecImage.vue';
const props = defineProps(['recItem'])
const accountStore = useAccountStore()

function gotoRecDetail(){
  console.log('accountStore.membershipOrder.status:', accountStore.membershipOrder.status)
  if (accountStore.user_id > 0) {
    if (accountStore.membershipOrder.status  === 'Active'){
      window.location.href = '/rec/detail?id=' + props.recItem?.id
    }else {
            window.location.href = '/membership/plan'
      }
  } else {
    window.location.href = "/login"
  }
  
}
</script>

<template>
  <div>
    <div class="py-4 border-b sm:hidden" @click="gotoRecDetail">
      <div class="flex">
        <RecImage class="w-16 h-16 ml-4 rounded" :category="props.recItem.energy"/>
        <div class="flex-1 ml-4">
          <div class="font-semibold">
            <p class="text-ellipsis" v-if="props.recItem?.serial !== '*'">{{props.recItem?.serial.length > 0 ? props.recItem?.serial : props.recItem?.title}}</p>
            <p v-else class="blur-sm">************</p>
          </div>
          <div class="flex">
            <div class="text-sm text-mainFontGray">{{props.recItem?.region}}</div>
            <div class="ml-2 text-sm text-mainFontGray">{{ props.recItem?.vintage_year }}/{{ props.recItem?.vintage_month }}</div>
            <div class="ml-2 text-sm text-mainFontGray">{{ props.recItem?.energy }}</div>
          </div>
          <div class="text-mainGreen">US$ {{ (props.recItem?.price/100).toFixed(2) }}</div>
        </div>
      </div>
    </div>
    <div class="hidden py-4 border-b sm:flex hover:bg-gray-100" @click="gotoRecDetail">
      <RecImage class="w-16 h-16 ml-4 rounded" :category="props.recItem.energy"/>
      <div class="self-center ml-4">
        <div class="text-xl font-semibold">
          <p class="text-ellipsis" v-if="props.recItem?.serial !== '*'">{{props.recItem?.serial}}</p>
          <p v-else class="blur-sm">************</p>
        </div>
        <div class="flex text-base">
          <div class="lg:w-30 text-mainFontGray">{{props.recItem?.region}}</div>
          <div class="ml-2 lg:w-30 lg:ml-4 text-mainFontGray">{{ props.recItem?.vintage_year }}/{{ props.recItem?.vintage_month }}</div>
          <div class="ml-2 lg:w-30 lg:ml-4 text-mainFontGray">{{ props.recItem?.energy }}</div>
        </div>
      </div>
      <div class="flex-1 ml-7"></div>
      <div class="self-center">
        <span class="text-xl font-semibold">$ {{ (props.recItem?.price/100).toFixed(2) }}</span>
      </div>
    </div>
  </div>
</template>
