<script setup lang="ts">
import router from '@/router';
import { useAccountStore } from '@/stores/modules/account';
import { useWeb3Store } from '@/stores/modules/web3';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import ChooseWalletModal from "@/components/modals/ChooseWalletModal.vue"
import ChoosePaymentModal from '@/components/modals/ChoosePaymentModal.vue'
import { useNewNotificationStore } from '@/stores/modules/newNotification';

const accountStore = useAccountStore();
const web3Store = useWeb3Store();
const route = useRoute();
const showMenu = ref<boolean>(false);
const showSubMenu = ref<boolean>(false);
const showSubMenu2 = ref<boolean>(false)
const scrollTop = ref<number>(0);
const bgWhite = ref<String>('bg-white border-0');
const textWhite = ref<String>('text-white');
const onScroll = () => {
  scrollTop.value = window.scrollY;
};
const isInRecMarketPlacePage = computed(() => route.path == '/nft/market');
const recMarketPlaceObject = computed(() =>
  route.path == '/' ? 'border-transparent' : isInRecMarketPlacePage.value ? 'text-mainGreen border-mainGreen' : 'border-transparent'
);
const isInRecListPage = computed(() => route.path == '/rec/list');
const recListObject = computed(() =>
  route.path == '/' ? 'border-transparent' : isInRecListPage.value ? 'text-mainGreen border-mainGreen' : 'border-transparent' 
);
const isInCCListPage = computed(() => route.path == '/carbon/list');
const ccListObject = computed(() =>
  route.path == '/' ? 'border-transparent' : isInCCListPage.value ? 'text-mainGreen border-mainGreen' : 'border-transparent' 
);
const isMembershipPlanPage = computed(() => route.path == '/membership/plan');
const membershipPlanObject = computed(() =>
  route.path == '/' ? 'border-transparent' : isMembershipPlanPage.value ? 'text-mainGreen border-mainGreen' : 'border-transparent' 
);
const isInBlogPage = computed(() => route.path == '/blogs')
const isBgShow = computed(() => scrollTop.value > 0);
const textClassObject = computed(() =>
  isBgShow.value || showMenu.value
    ? 'text-black'
    : (route.path == '/' || route.path == '/about')
    ? 'text-white'
    : 'text-black'
);
const bgColorObject = computed(() => (isBgShow.value ? bgWhite.value : ''));

watch(
  () => scrollTop.value,
  (newValue, oldValue) => {
    /*console.log(
      scrollTop.value,
      isBgShow.value,
      textClassObject.value,
      bgColorObject.value,
      route.path,
      isInRecMarketPlacePage.value
    );*/
  }
);

function toggleNavbar() {
  showMenu.value = !showMenu.value;
  //console.log(showMenu.value, textClassObject.value);
  if (showMenu.value) {
  } else {
  }
}

function toggleSubMenu2(){
  showSubMenu2.value = !showSubMenu2.value
}

function signInClick(){
 window.location.href = '/login'
}

function singOutClick(){
  accountStore.signOut();
  router.push('/');
}

function gotoMyRec(){
  window.location.href = '/person/rec?tab=Listed'
}

function gotoMyCC(){
  window.location.href = '/person/cc?tab=Listed'
}

function gotoMyNft(){
  window.location.href = '/person/nft?id=' + accountStore.user_id + '&tab=Listed'
}

function gotoMyTransaction(){
  window.location.href = '/person/transaction?tab=MintRequest'
}

function gotoCarbonList(){
  window.location.href = '/carbon/list'
}

function gotoRecList(){
  window.location.href = '/rec/list'
}

function gotoNFTList(){
 window.location.href = '/nft/market' 
}

function gotoMembershipPlan(){
  window.location.href = '/membership/plan'
}

function gotoProfile(){
  window.location.href = '/person/profile?tab=Profile'
}

const newNotificationStore = useNewNotificationStore()
onMounted(() => {
  window.addEventListener('scroll', onScroll);
  setTimeout(() => {
    if(accountStore.isLogin){
      newNotificationStore.load()
    }
  }, 500);
});

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll);
});
</script>

<template>
  <nav class="sticky top-0 z-40 w-full" :class="[bgColorObject, textClassObject]">
    <div
      class="flex flex-wrap items-center justify-between w-full px-2 py-0 mx-auto xl:px-0 xl:container xl:max-w-7xl"
    >
      <div class="flex justify-between w-full lg:w-auto">
        <a class="flex items-center" href="/">
          <div class="relative flex items-center ml-2 mr-32 h-28 xl:ml-0">
            <img class="object-fill h-12 sm:h-24" src="/images/logo_green.png" />
            <div class="relative">
              <p class="mt-2 text-2xl font-bold sm:text-4xl text-mainGreen">GREX</p>
              <div v-if="web3Store.useTestNet" class="flex px-0 py-0 text-xs">
                <span class="flex-1"></span>
                <span class="absolute right-0 px-2 py-0 text-black bg-yellow-500 rounded">Demo</span>
              </div>
            </div>
          </div>
          <div v-if="isInBlogPage" class="ml-4 text-4xl"></div>
        </a>
        <div class="flex">
          <button class="mr-4 lg:hidden" @click="toggleNavbar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-6 h-6 scale-125"
            >
              <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <button v-if="accountStore.isLogin" class="mr-4 lg:hidden" @click="toggleSubMenu2">
            <!--<img class="w-6 h-6 rounded-full ring-2 ring-mainGreen ring-offset-2 ring-offset-white" src="/avatar.svg"/>-->
            <div class="flex items-center justify-center w-6 h-6 mx-auto text-base font-semibold text-center rounded bg-mainGreen">
              <p class="text-black">{{ accountStore.user_name.slice(0, 1).toUpperCase() }}</p>
            </div>
          </button>
        </div>
      </div>
      <div
        v-show="showSubMenu2"
        class="z-10 w-full p-4 mt-2 text-black bg-white border border-white divide-y rounded-md shadow-lg shadow-black/50 lg:hidden"
        @mouseleave="showSubMenu2 = false"
      >
        <div class="block px-4 py-4 text-xl font-semibold sm:text-2xl">
          <p>{{accountStore.user_name}}</p>
          <button v-if="!web3Store.active" class="mt-2 text-base font-normal sm:text-xl text-mainGreen disabled:text-mainFontGray" @click="web3Store.showChooseWalletModal=true" disabled>Connect Wallet</button>
          <p v-else class="mt-2 text-base font-normal text-gray-500 sm:text-lg">{{web3Store.address.substring(0, 6)}}...{{web3Store.address.substring(web3Store.address.length - 4)}}</p>
        </div>
        <button @click="gotoMyRec"
          to="/my/rec/list?category=listed"
          class="block w-full px-4 py-4 text-base text-black sm:text-xl hover:text-mainGreen"
          ><div class="flex items-center">
            <img class="h-6 sm:h-8" src="/images/rec.svg">
            <p class="ml-2 text-base sm:text-xl">My RECs</p>
          </div></button
        >
        <button @click="gotoMyCC"
          to="/my/cc/list?category=listed"
          class="block w-full px-4 py-4 text-xl text-black hover:text-mainGreen"
          ><div class="flex items-center">
            <img class="h-6 sm:h-8" src="/images/carbon.svg">
            <p class="ml-2 text-base sm:text-xl">My CCs</p>
          </div></button
        >
        <button
          @click="gotoMyNft"
          class="block w-full px-4 py-4 text-xl hover:text-mainGreen"
          ><div class="flex items-center">
            <img class="h-6 sm:h-8" src="/images/nft.svg">
            <p class="ml-2 text-base sm:text-xl">My NFTs</p>
          </div></button
        >
        <button
          @click="gotoMyTransaction"
          class="block w-full px-4 py-4 text-xl hover:text-mainGreen"
          ><div class="flex items-center">
            <img class="h-6 sm:h-8" src="/images/transaction.svg">
            <p class="ml-2 text-base sm:text-xl">My Transactions</p>
          </div></button
        >
        <button @click="gotoProfile"
          class="block w-full px-4 py-4 text-xl hover:text-mainGreen"
          ><div class="flex items-center">
            <img class="h-6 sm:h-8" src="/images/profile.svg">
            <p class="ml-2 text-base sm:text-xl">Account</p> 
          </div></button
        >
        <button class="block w-full px-4 py-4 text-sm hover:text-mainGreen" @click="singOutClick">
          <div class="flex items-center">
            <img class="h-6 sm:h-8" src="/images/logout.svg">
            <p class="ml-2 text-base sm:text-xl">Sign Out</p>
          </div>
        </button>
      </div>
      <div class="z-10 w-full lg:block lg:w-auto" :class="{ hidden: !showMenu }">
        <ul
          class="flex flex-col items-center p-2 mt-0 font-medium bg-gray-100 border rounded-lg shadow-lg sm:shadow-none border-collapsegray-100 lg:flex-row lg:bg-transparent lg:border-transparent"
        >
          <li>
            <button @click="gotoCarbonList" class="block py-0 pl-3 pr-4 text-lg" :class="ccListObject">
              <div>CC Listing</div>
              <div class="flex justify-center">
                <p class="w-10 text-center border-t-4" :class="ccListObject"></p>
              </div>
            </button>
          </li>
          <li>
            <button @click="gotoRecList" class="block py-0 pl-3 pr-4 text-lg" :class="recListObject">
              <div>REC Listing</div>
              <div class="flex justify-center">
                <p class="w-10 text-center border-t-4" :class="recListObject"></p>
              </div>
            </button>
          </li>
          <li>
            <button @click="gotoNFTList" class="block py-0 pl-3 pr-4 text-lg" :class="recMarketPlaceObject">
              <div class="">NFT Marketplace</div>
              <div class="flex justify-center">
                <p class="w-10 text-center border-t-4" :class="recMarketPlaceObject"></p>
              </div>
            </button>
          </li>
          <li>
            <button @click="gotoMembershipPlan" class="block py-0 pl-3 pr-4 text-lg" :class="membershipPlanObject">
              <div>Membership Plan</div>
              <div class="flex justify-center">
                <p class="w-10 text-center border-t-4" :class="membershipPlanObject"></p>
              </div>
            </button>
          </li>
          <li class="items-center hidden py-0 pl-3 lg:flex" v-if="accountStore.isLogin">
            <div class="relative block">
              <!--<img class="w-8 h-8 rounded-full ring-2 ring-mainGreen ring-offset-2 ring-offset-white" src="/images/avatar.svg" @mouseenter="showSubMenu = true"/>-->
              <div class="relative flex items-center justify-center h-10 px-2 mx-auto text-2xl font-semibold text-center rounded-lg bg-mainGreen"  @mouseenter="showSubMenu = true">
                <p class="flex items-center justify-center px-2 text-xl bg-white rounded-full text-mainGreenHover">{{ accountStore.user_name.slice(0, 1).toUpperCase() }}</p>
                <p v-if="web3Store.active" class="pl-2 text-base text-white">{{web3Store.address.substring(0, 6)}}...{{web3Store.address.substring(web3Store.address.length - 4)}}</p>
                <div v-if="newNotificationStore.hasNewNotify" class="absolute right-0 flex items-center justify-center w-2 h-2 -top-1">
                  <span class="absolute w-2 h-2 bg-red-400 rounded-full animate-ping"></span>
                  <span class="w-2 h-2 bg-red-500 rounded-full"></span>
                </div>
              </div>
              <div
                v-show="showSubMenu"
                class="p-4 mt-2 text-black bg-white border border-white divide-y rounded-md shadow-lg w-72 shadow-black/50 lg:absolute lg:right-2"
                @mouseleave="showSubMenu = false"
              >
                <div class="block px-4 py-4 text-2xl font-semibold">
                  <p class="truncate text-ellipsis">{{accountStore.user_name}}</p>
                  <button v-if="!web3Store.active" class="mt-2 text-xl font-normal text-mainGreen disabled:text-mainFontGray" @click="web3Store.showChooseWalletModal=true" disabled>Connect Wallet</button>
                  <p v-else class="mt-2 text-sm font-normal text-gray-500 truncate text-ellipsis">{{web3Store.address}}</p>
                </div>
                <button @click="gotoMyRec"
                  to="/my/rec/list?category=listed"
                  class="block px-4 py-4 text-xl text-black hover:text-mainGreen"
                  ><div class="flex items-center">
                    <img class="h-8" src="/images/rec.svg">
                    <p class="ml-2 text-xl">My RECs</p>
                  </div></button
                >
                <button @click="gotoMyCC"
                  to="/my/rec/list?category=listed"
                  class="block px-4 py-4 text-xl text-black hover:text-mainGreen"
                  ><div class="flex items-center">
                    <img class="h-8" src="/images/carbon.svg">
                    <p class="ml-2 text-xl">My CCs</p>
                  </div></button
                >
                <button
                  @click="gotoMyNft"
                  class="block px-4 py-4 text-xl hover:text-mainGreen"
                  >
                    <div class="relative flex items-center">
                      <img class="h-8" src="/images/nft.svg">
                      <p class="ml-2 text-xl">My NFTs</p>
                    </div>
                </button>
                <button
                  @click="gotoMyTransaction"
                  class="block px-4 py-4 text-xl hover:text-mainGreen"
                  >
                  <div class="relative flex items-center">
                    <img class="h-8" src="/images/transaction.svg">
                    <p class="ml-2 text-xl">My Transactions</p>
                  </div>
                </button>
                <button @click="gotoProfile"
                  class="px-4 py-4 text-xl lock hover:text-mainGreen"
                  ><div class="relative flex items-center">
                    <img class="h-8" src="/images/profile.svg">
                    <p class="ml-2 text-xl">Account</p> 
                    <div v-if="newNotificationStore.hasNewNotify" class="absolute flex items-center justify-center w-3 h-3 -right-3 -top-0">
                      <span class="w-2 h-2 bg-red-500 rounded-full"></span>
                    </div>
                  </div></button
                >
                <button class="block w-full px-4 py-4 text-sm hover:text-mainGreen" @click="singOutClick">
                  <div class="flex items-center">
                    <img class="h-8" src="/images/logout.svg">
                    <p class="ml-2 text-xl">Sign Out</p>
                  </div>
                </button>
              </div>
            </div>
          </li>
          <li class="py-0 pl-3" v-else>
            <div class="flex items-center justify-center">
              <button class="px-4 py-1 text-lg text-white rounded-lg bg-mainGreen" @click="signInClick"><span>Sign In</span></button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <choose-wallet-modal></choose-wallet-modal>
    <choose-payment-modal></choose-payment-modal>
  </nav>
</template>
