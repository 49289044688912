<script lang="ts" setup>
import NFTImage from './NFTImage.vue'

const props = defineProps(['item'])

function gotoDetail(){
  window.location.href = '/nft/detail?order_id=' + props.item.id + '&nft_id=' + props.item.rec_nft.id+'&owner_id=' + props.item.user_id
}
</script>

<template>
  <div
    class="mt-6 border border-gray-300 first:mt-0 md:mt-0 rounded-xl hover:bg-gray-100" @click="gotoDetail"
  >
  <div class="relative">
    <NFTImage class="w-full h-64 rounded-t-lg" :category="props.item.rec_nft?.rec_cert?.category"></NFTImage>
  </div>
    
    <p class="mx-4 mt-2 font-semibold text-gray-500">{{  props.item.user?.user_name }}</p>
    <p class="px-4 overflow-hidden text-base font-semibold truncate lg:w-68 text-ellipsis">
      {{ props.item.rec_nft?.name }}
    </p>
    <p class="mx-4 mt-1 text-sm lg:w-68 text-ellipsis">
      {{ item.rec_nft.rec_cert.category }} - {{item.rec_nft.rec_cert.country == 'Others' ? item.rec_nft.rec_cert.other_country : item.rec_nft.rec_cert.country }}
    </p>
    <div
      class="flex items-center justify-center w-full py-2 mx-0 mt-2 mb-0 text-xl text-white rounded-b-xl bg-mainGreen"
    >
      <span class="">US$</span>&nbsp;{{ (props.item.price/100).toFixed(2) }}
    </div>
  </div>
</template>
