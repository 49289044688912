import type { RecNftRetire, RecNFTMint, RecNftOffer, RecNftOrder, RecNftOrderSale, RecTransfer } from "@/model/model";
import type { SearchNFTMintResponse, CreateNFTMintRequest, SearchNFTMintRequest, SearchRecTransferRequest, SearchRecTransferRessponse, SignMessageRequest, SearchMyNFTListRequest, SearchMyNFTListResponse, CreateMyNFTORderRequest, SearchNFTMarketRequest,  SearchNFTMarketResponse, CreateOfferRequest, BuyOrderRequest, UpdateNFTORderSign, BuyOrderResponse,  UpdateOfferRequest,  OfferAceptRequest,  SearchUserNFTRequest,  SearchUserNFTResponse,  SearchUserNFTOrderResponse,  SearchUserNFTOwnedResponse,  NFTOwnDetailResponse,  SearchUserOfferResponse,  SearchUserNftEventResponse,  OfferRejectRequest,  BuyOfferRequest,   SearchMySaleResponse,  SearchMySaleRequest,  BuySignRequest,  CheckNftOrderSellRequest,  CreateMyNFTOrderResopnse,  BuySignResponse,  BuyOfferResponse,  BuyOfferSignReqeust,  BuyOfferSignResponse,  SearchNFTMarketChartRequest, SearchNFTMarketChartItem, RecNftRetireRequest, RecNftRetireSearchResponse, RecNftRetireSearch, NFTOfferLastStatResponse, NFTOrderRelatedRequest, NFTOrderRelatedResponse, SearchNFTMarketCharResponse, SignUniqueSaleRequest, OfferPreAcceptRequest } from "@/model/reqRes";
import { API_CREATE_REC_TRANSFER, API_GET_SALE_BY_NFT_ID, API_GET_SALE_BY_ORDER_ID, API_NFT_BUY_SIGN, API_NFT_CAN_OFFER_AMOUNT, API_NFT_CAN_SALE_AMOUNT, API_NFT_MARKET_SEARCH, API_NFT_MINT_REQUEST, API_NFT_MINT_REQUEST_CANCEL, API_NFT_MINT_SEARCH, API_NFT_MY_LIST_SEARCH, API_NFT_MY_OFFER, API_NFT_MY_OFFER_CANCEL, API_NFT_MY_ORDER, API_NFT_MY_OWN_FROM_NFT_ID, API_NFT_MY_PRE_ORDER, API_NFT_MY_SALE_PAYMENT, API_NFT_MY_SALE_SEARCH, API_NFT_OFFER_ACCEPT, API_NFT_OFFER_BUY_SIGN, API_NFT_OFFER_LAST_STAT, API_NFT_OFFER_PRE_ACCEPT, API_NFT_ORDER_BUY, API_NFT_ORDER_CHECK_SELL, API_NFT_ORDER_DETAIL, API_NFT_ORDER_RELATED, API_NFT_ORDER_VIEW, API_NFT_RETIRE, API_NFT_SELF_UER_SEARCH, API_NFT_SIGN_MESSAGE, API_NFT_UNIQUE_SALE_CAN_PAY, API_NFT_USER_DETAIL, API_NFT_USER_SEARCH, API_SERACH_REC_TRANSFER, API_TOP_NFT_ORDERS } from "@/utils/const";
import { defaults, mande } from "mande";
import type { BaseResponse } from './../model/reqRes';
import { API_NFT_DETAIL, API_NFT_MINT, API_NFT_MY_SALE, API_NFT_MY_ORDER_CANCEL, API_NFT_OWN_DETAIL, API_NFT_OFFER_REJECT, API_NFT_OFFER_BUY, API_NFT_MARKET_CHART_SEARCH, API_NFT_RETIRE_LIST, API_NFT_CAN_LIST_AMOUNT, API_NFT_MY_SALE_CALLBACK, API_NFT_UNIQUE_SALE_SIGN } from './../utils/const';
import type { RecNft } from './../model/model';

const recTransferApi = ()=> mande(API_CREATE_REC_TRANSFER);
const searchRecTransferApi = ()=> mande(API_SERACH_REC_TRANSFER);
const recNftDetailApi = ()=> mande(API_NFT_DETAIL)
const recNftSignMessageApi = ()=> mande(API_NFT_SIGN_MESSAGE)
const nftMintApi = ()=> mande(API_NFT_MINT)
const nftMintRequestApi = () => mande(API_NFT_MINT_REQUEST)
const nftMintRequestCancelApi = () => mande(API_NFT_MINT_REQUEST_CANCEL)
const nftMintSearchAPi = ()=> mande(API_NFT_MINT_SEARCH)
const nftMyListSearchAPi = ()=> mande(API_NFT_MY_LIST_SEARCH)
const nftUserSearchApi = ()=> mande(API_NFT_USER_SEARCH)
const nftSelfUserSearchApi = ()=>mande(API_NFT_SELF_UER_SEARCH)
const nftOwnDetailAPi = ()=> mande(API_NFT_OWN_DETAIL)
const nftMyOwnDetailFromNftApi = ()=> mande(API_NFT_MY_OWN_FROM_NFT_ID)
const nftUserDetailAPi = ()=> mande(API_NFT_USER_DETAIL)
const nftMyOrderApi = ()=> mande(API_NFT_MY_ORDER)
const nftMyOrderCancelApi = ()=> mande(API_NFT_MY_ORDER_CANCEL)
const nftMyPreOrderApi = ()=> mande(API_NFT_MY_PRE_ORDER)
const nftMarketSearchApi = ()=> mande(API_NFT_MARKET_SEARCH)
const nftMarketChartSearchApi = ()=> mande(API_NFT_MARKET_CHART_SEARCH)
const nftOrderDetailApi = ()=> mande(API_NFT_ORDER_DETAIL)
const nftOrderViewApi = ()=> mande(API_NFT_ORDER_VIEW)
const nftMyOfferApi = ()=> mande(API_NFT_MY_OFFER)
const nftOfferAcceptApi = ()=> mande(API_NFT_OFFER_ACCEPT)
const nftOfferPreAcceptApi = ()=> mande(API_NFT_OFFER_PRE_ACCEPT)
const nftOfferRejectApi = ()=> mande(API_NFT_OFFER_REJECT)
const nftMyOfferCancelApi = ()=> mande(API_NFT_MY_OFFER_CANCEL)
const nftOrderBuyApi = ()=> mande(API_NFT_ORDER_BUY)
const nftOfferBuyApi = ()=> mande(API_NFT_OFFER_BUY)
const nftOfferBuySignAPi = ()=> mande(API_NFT_OFFER_BUY_SIGN)
const nftBuySignApi = ()=> mande(API_NFT_BUY_SIGN)
const nftMySaleApi = ()=> mande(API_NFT_MY_SALE)
const nftMySaleCallbackApi = ()=>mande(API_NFT_MY_SALE_CALLBACK)
const nftMySalePaymentApi = ()=> mande(API_NFT_MY_SALE_PAYMENT)
const nftGetSaleByOrderApi = ()=> mande(API_GET_SALE_BY_ORDER_ID)
const nftGetSaleByNftAPi = () => mande(API_GET_SALE_BY_NFT_ID)
const nftMySaleSearchApi = ()=> mande(API_NFT_MY_SALE_SEARCH)
const nftTopApi = ()=> mande(API_TOP_NFT_ORDERS)
const nftOrderCheckSellApi = ()=> mande(API_NFT_ORDER_CHECK_SELL)
const nftRetireAPi = () => mande(API_NFT_RETIRE)
const nftRetireListApi = () => mande(API_NFT_RETIRE_LIST)
const nftCanListAmountApi = () => mande(API_NFT_CAN_LIST_AMOUNT)
const nftCanOfferAmountApi = () => mande(API_NFT_CAN_OFFER_AMOUNT)
const nftCanSellAmountApi = () => mande(API_NFT_CAN_SALE_AMOUNT)
const nftOfferLastStatApi = () => mande(API_NFT_OFFER_LAST_STAT)
const nftOrderRelatedApi = () => mande(API_NFT_ORDER_RELATED)
const nftUniqueSaleSignApi = () => mande(API_NFT_UNIQUE_SALE_SIGN)
const nftUniqueSaleCanPayApi = () => mande(API_NFT_UNIQUE_SALE_CAN_PAY)

export async function signNFTUniqueSale(req: SignUniqueSaleRequest){
    return nftUniqueSaleSignApi().post<BaseResponse<RecNftOrderSale>>(req).catch(error => {
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrderSale>; 
    });
}

export async function canPayNFTUniqueSale(saleId: number){
    return nftUniqueSaleCanPayApi().get<BaseResponse<string>>(saleId).catch(error => {
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>; 
    });
}

export async function getNFTOrderRelated(req: NFTOrderRelatedRequest) {
    return nftOrderRelatedApi().post<BaseResponse<NFTOrderRelatedResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<NFTOrderRelatedResponse>; 
    });
}

export async function getNftOfferLastStat(nftId:number){
    return nftOfferLastStatApi().get<BaseResponse<NFTOfferLastStatResponse>>(nftId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<NFTOfferLastStatResponse>; 
    });
}

export async function getNftCanSellAmount(listId:string){
    return await nftCanSellAmountApi().get<BaseResponse<number>>(listId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<number>; 
    });
}

export async function getNftCanOfferAmount(nftId:string, userId:string){
    return await nftCanOfferAmountApi().get<BaseResponse<number>>(nftId + '/' + userId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<number>; 
    });
}

export async function getUserNftCanListAmount(nftId:string, userId:string) {
    return await nftCanListAmountApi().get<BaseResponse<number>>(nftId + '/' + userId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<number>; 
    });
}

export async function getNftCanListAmount(nftId:string) {
    return await nftCanListAmountApi().get<BaseResponse<number>>(nftId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<number>; 
    });
}

export async function getSaleByNftId(nftId:string){
    return await nftGetSaleByNftAPi().get<BaseResponse<RecNftOrderSale[]>>(nftId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrderSale[]>; 
    });
}

export async function getSalesByNftId(nftId:string) {
    return await nftGetSaleByNftAPi().get<BaseResponse<RecNftOrderSale[]>>(nftId).catch(error => {
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrderSale[]>; 
    });
}

export async function getSalesByOrderId(orderId:string) {
    return await nftGetSaleByOrderApi().get<BaseResponse<RecNftOrderSale[]>>(orderId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrderSale[]>; 
    });
}

export async function checkSellNftOrder(req: CheckNftOrderSellRequest) {
    return await nftOrderCheckSellApi().post<BaseResponse<string>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>; 
    });
}

export async function getNftTops(){
    return await nftTopApi().get<BaseResponse<RecNftOrder[]>>('').catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrder[]>; 
    });
}

export async function searchMySale(req: SearchMySaleRequest) {
    return await nftMySaleSearchApi().post<BaseResponse<SearchMySaleResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchMySaleResponse>; 
    });
}

export async function getNFTOrderView(id: number){
    return await nftOrderViewApi().get<BaseResponse<number>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<number>; 
    });
}

export async function getNFTUserDetail(userId:number, nftId: number){
    return await nftUserDetailAPi().get<BaseResponse<NFTOwnDetailResponse>>(userId + '/' + nftId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<NFTOwnDetailResponse>; 
    });
}

export async function getOwnNFTDetail(id:number){
    return nftOwnDetailAPi().get<BaseResponse<NFTOwnDetailResponse>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<NFTOwnDetailResponse>; 
    });
}

export async function getMyOwnNFTDetail(nftId:number){
    return nftMyOwnDetailFromNftApi().get<BaseResponse<NFTOwnDetailResponse>>(nftId).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<NFTOwnDetailResponse>; 
    });
}

export async function rejectOfferRequest(req: OfferRejectRequest){
    return await nftOfferRejectApi().post<BaseResponse<string>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>; 
    });
}

export async function acceptOfferPreRequest(req: OfferPreAcceptRequest){
    return await nftOfferPreAcceptApi().post<BaseResponse<number>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<number>; 
    });
}

export async function acceptOfferRequest(req: OfferAceptRequest){
    return await nftOfferAcceptApi().post<BaseResponse<string>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>; 
    });
}

export async function checkOrderSalePayment(id:number){
    return await nftMySalePaymentApi().get<BaseResponse<string>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>; 
    });
}

export async function  getOrderSaleById(id:number) {
    return await nftMySaleApi().get<BaseResponse<RecNftOrderSale>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrderSale>; 
    });
}

export async function mySaleOrderCallback(id:number){
    return await nftMySaleCallbackApi().get<BaseResponse<RecNftOrderSale>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrderSale>; 
    });
}

export async function offerBuySign(req:BuyOfferSignReqeust){
    return await nftOfferBuySignAPi().post<BaseResponse<RecNftOrderSale>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrderSale>; 
    });
}

export async function offerBuy(req:BuyOfferRequest){
    return await nftOfferBuyApi().post<BaseResponse<BuyOfferResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<BuyOfferResponse>; 
    });
}

export async function orderBuy(req:BuyOrderRequest) {
    return await nftOrderBuyApi().post<BaseResponse<BuyOrderResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<BuyOrderResponse>; 
    });
}

export async function buySign(req:BuySignRequest){
    return await nftBuySignApi().post<BaseResponse<BuySignResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<BuySignResponse>; 
    });
}

export async function cancelMyOffer(id:number) {
    return await nftMyOfferCancelApi().get<BaseResponse<string>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<string>; 
    });
}

export async function createMyOffer(req:CreateOfferRequest) {
    return await nftMyOfferApi().post<BaseResponse<RecNftOffer>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOffer>; 
    });
}

export async function updateMyOffer(req:UpdateOfferRequest){
    return await nftMyOfferApi().put<BaseResponse<RecNftOffer>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOffer>; 
    });
}

export async function getNFTOrderDetail(id: number) {
    return await nftOrderDetailApi().get<BaseResponse<RecNftOrder>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrder>; 
    });
}

export async function nftMarketChartSearch(req:SearchNFTMarketChartRequest){
    return await nftMarketChartSearchApi().post<BaseResponse<SearchNFTMarketCharResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchNFTMarketCharResponse>; 
    });
}

export async function nftMarketSearch(req:SearchNFTMarketRequest) {
    return await nftMarketSearchApi().post<BaseResponse<SearchNFTMarketResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchNFTMarketResponse>; 
    });
}

export async function createMyPreNFTOrder(req:CreateMyNFTORderRequest) {
    return await nftMyPreOrderApi().post<BaseResponse<CreateMyNFTOrderResopnse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<CreateMyNFTOrderResopnse>; 
    });
}

export async function cancelNFTOrder(id: number) {
    return await nftMyOrderCancelApi().get<BaseResponse<string>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrder>; 
    });
}

export async function updateNFTOrderSign(req:UpdateNFTORderSign){
    return await nftMyOrderApi().post<BaseResponse<RecNftOrder>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftOrder>; 
    });
}

export async function searchSelfUserNftEventList(req:SearchUserNFTRequest) {
    return await nftSelfUserSearchApi().post<BaseResponse<SearchUserNftEventResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchUserNftEventResponse>; 
    });
}

export async function searchUserNftEventList(req:SearchUserNFTRequest) {
    return await nftUserSearchApi().post<BaseResponse<SearchUserNftEventResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchUserNftEventResponse>; 
    });
}

export async function searchSelfUserOfferList(req:SearchUserNFTRequest){
    return await nftSelfUserSearchApi().post<BaseResponse<SearchUserOfferResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchUserOfferResponse>; 
    });
}

export async function searchUserOfferList(req:SearchUserNFTRequest){
    return await nftUserSearchApi().post<BaseResponse<SearchUserOfferResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchUserOfferResponse>; 
    });
}

export async function searchSelfUserNftOwnedList(req: SearchUserNFTRequest){
    return await nftSelfUserSearchApi().post<BaseResponse<SearchUserNFTOwnedResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchUserNFTOwnedResponse>; 
    });
}

export async function searchUserNftOwnedList(req: SearchUserNFTRequest){
    return await nftUserSearchApi().post<BaseResponse<SearchUserNFTOwnedResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchUserNFTOwnedResponse>; 
    });
}

export async function searchSelfUserNftOrderList(req: SearchUserNFTRequest){
    return await nftSelfUserSearchApi().post<BaseResponse<SearchUserNFTOrderResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchUserNFTOrderResponse>; 
    });
}

export async function searchUserNftOrderList(req: SearchUserNFTRequest){
    return await nftUserSearchApi().post<BaseResponse<SearchUserNFTOrderResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchUserNFTOrderResponse>; 
    });
}

export async function searchUserNFTList(req: SearchUserNFTRequest) {
    return await nftUserSearchApi().post<BaseResponse<SearchUserNFTResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchUserNFTResponse>; 
    });
}

export async function searchMyNFTList(req: SearchMyNFTListRequest) {
    return await nftMyListSearchAPi().post<BaseResponse<SearchMyNFTListResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchMyNFTListResponse>; 
    });
}

export async function getNFTMintRequest(id: number) {
    return await nftMintRequestApi().get<BaseResponse<RecNFTMint>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNFTMint>; 
    });
}

export async function createNFTMintRequest(req: RecNFTMint) {
    return await nftMintRequestApi().post<BaseResponse<RecNFTMint>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNFTMint>; 
    });
}

export async function updateNFTMintRequest(req: RecNFTMint) {
    return await nftMintRequestApi().put<BaseResponse<RecNFTMint>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNFTMint>; 
    });
}

export async function cancelNFTMintRequest(id: number){
    return await nftMintRequestCancelApi().get<BaseResponse<RecNFTMint>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNFTMint>; 
    });
}

export async function createNFTMint(req: CreateNFTMintRequest) {
    return await nftMintApi().post<BaseResponse<RecTransfer>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecTransfer>; 
    });
}

export async function searchNFTMint(req: SearchNFTMintRequest) {
    return await nftMintSearchAPi().post<BaseResponse<SearchNFTMintResponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchNFTMintResponse>; 
    });
}

export async function createRecTransfer(req: CreateNFTMintRequest) {
    return await recTransferApi().post<BaseResponse<RecTransfer>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecTransfer>; 
    });
}

export async function searchRecTransfer(req: SearchRecTransferRequest) {
    return await searchRecTransferApi().post<BaseResponse<SearchRecTransferRessponse>>(req).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<SearchRecTransferRessponse>; 
    });
}

export async function getNftDetail(id: string) {
    return await recNftDetailApi().get<BaseResponse<RecNft>>(id).catch(error =>{
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNft>; 
    });
}

export async function getSignMessage(req: SignMessageRequest){
    return await recNftSignMessageApi().post<BaseResponse<String>>(req).catch(error =>{
        console.log(error)
        return { code : 500, message: 'server error', data: null} as BaseResponse<String>; 
    });
}

export async function sendNFTRetire(req: RecNftRetireRequest){
    return await nftRetireAPi().post<BaseResponse<RecNftRetire>>(req).catch(error =>{
        console.log(error)
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftRetire>; 
    });
}

export async function getNFTRetireById(id: string) {
    return await nftRetireAPi().get<BaseResponse<RecNftRetire>>('/detail/' + id).catch(error =>{
        console.log(error)
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftRetire>; 
    });
}

export async function searchSelfUserNFTRetire(req: RecNftRetireSearch) {
    return await nftRetireListApi().post<BaseResponse<RecNftRetireSearchResponse>>(req).catch(error =>{
        console.log(error)
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftRetireSearchResponse>; 
    });
}

export async function searchUserNFTRetire(req: RecNftRetireSearch) {
    return await nftRetireListApi().post<BaseResponse<RecNftRetireSearchResponse>>(req).catch(error =>{
        console.log(error)
        return { code : 500, message: 'server error', data: null} as BaseResponse<RecNftRetireSearchResponse>; 
    });
}