<script lang="ts" setup>
import SimpleModal from '@/components/control/simpleModal.vue'
import { useWeb3Store } from '@/stores/modules/web3';
import { computed, ref,toRef } from '@vue/runtime-core';
const web3Store = useWeb3Store()
const isChrome = computed(()=> {
    var ua = window.navigator.userAgent.toLocaleLowerCase()
    return ua.match(/chrome|chromium|crios/i) && ua.indexOf('edg') == -1 && ua.indexOf('opr') == -1
})
const isFirefox = computed(() => {
    var ua= window.navigator.userAgent.toLowerCase()
    console.log(ua)
    return ua.indexOf('firefox') > -1
})
const isEdge = computed(()=> {
    var ua = window.navigator.userAgent.toLocaleLowerCase()
    return ua.match(/edg/i)
})
const isOpera = computed(()=> {
    var ua = window.navigator.userAgent.toLocaleLowerCase()
    return ua.match(/opr\//i)
})
const isOtherBrowser = computed(()=> {
    return !isChrome && !isFirefox && !isEdge && !isOpera
})
function onconfirm(){
    web3Store.showInstallMetaMask = false
    web3Store.isChainIdCorrect = true
    web3Store.showChooseWalletModal = false
}

async function switchNetwork(){
    const success = await web3Store.switchToPolygonNetwork()
    if(success){
        web3Store.showChooseWalletModal = false
        web3Store.showInstallMetaMask = false
        web3Store.isChainIdCorrect = true
        window.location.reload()
    }
}
const targetWindow = ref<Window>(window)

function onClose(){
    web3Store.showInstallMetaMask = false
    web3Store.isChainIdCorrect = true
    web3Store.showChooseWalletModal=false
}
</script>

<template>
    <SimpleModal :open="web3Store.showChooseWalletModal" :show-footer="false" @confirm="onconfirm" @close="onClose">
        <template v-slot:title>
            <div v-if="web3Store.showInstallMetaMask" class="relative flex items-center justify-center w-full">
                <button class="absolute top-0 left-0" @click="web3Store.showInstallMetaMask=false">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </button>
                <p class="font-semibold">MetaMask</p>
            </div>
            <div v-if="!web3Store.isChainIdCorrect" class="font-semibold">MetaMask Network Warning</div>
            <p v-if="!web3Store.showInstallMetaMask && web3Store.isChainIdCorrect" class="font-semibold">Connect Wallet</p>
        </template>
        <template v-slot:content>
            <div v-if="web3Store.showInstallMetaMask" class="p-3 m-3 w-72">
                <img class="block w-20 h-20 mx-auto" src="/images/metamask_logo.svg"/>
                <p class="mx-auto mt-3 text-xl text-center">Install MetaMask</p>
                <p class="mt-3 text-base text-center text-gray-500">To connect your MetaMask Wallet,</p>
                <p class="text-center text-gray-500">install the browser extension.</p>
                <button v-if="isEdge" class="flex items-center w-full px-6 py-3 mt-6 bg-gray-100 rounded-lg hover:bg-gray-200"
                    @click="targetWindow.open('https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US', '_blank')">
                    <img class="w-8 h-8" src="/images/Edge.png"/>
                    <span class="ml-3 text-lg">Install the Extension</span>
                </button>
                <button v-if="isChrome" class="flex items-center w-full px-6 py-3 mt-6 bg-gray-100 rounded-lg hover:bg-gray-200"
                    @click="targetWindow.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', '_blank')">
                    <img class="w-8 h-8" src="/images/chrome.png"/>
                    <span class="ml-3 text-lg">Install the Extension</span>
                </button>
                <button v-if="isFirefox" class="flex items-center w-full px-6 py-3 mt-6 bg-gray-100 rounded-lg hover:bg-gray-200"
                    @click="targetWindow.open('https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/', '_blank')">
                    <img class="w-8 h-8" src="/images/Firefox.png"/>
                    <span class="ml-3 text-lg">Install the Extension</span>
                </button>
                <button v-if="isOpera" class="flex items-center w-full px-6 py-3 mt-6 bg-gray-100 rounded-lg hover:bg-gray-200"
                    @click="targetWindow.open('https://addons.opera.com/en-gb/extensions/details/metamask-10/', '_blank')">
                    <img class="w-8 h-8" src="/images/Opera.png"/>
                    <span class="ml-3 text-lg">Install the Extension</span>
                </button>
                <button v-if="isOtherBrowser" class="flex items-center w-full px-6 py-3 mt-6 bg-gray-100 rounded-lg hover:bg-gray-200"
                    @click="targetWindow.open('https://metamask.io/download/', '_blank')">
                    <span class="ml-3 text-lg">Install the Extension</span>
                </button>
            </div>
            <div v-if="!web3Store.isChainIdCorrect" class="p-0 m-0">
                <img class="w-40 mx-auto" src="/images/Polygon_Logo.svg"/>
                <p class="mt-3 text-lg text-center">Polyon network support only</p>
                <button class="flex items-center justify-center px-6 py-4 m-3 text-white bg-red-500 rounded-lg w-72 hover:bg-red-600" @click="switchNetwork">Connect to {{web3Store.useTestNet? 'Mumbai Testnet': 'Mainnet'}}</button>
            </div>
            <button v-if="!web3Store.showInstallMetaMask && web3Store.isChainIdCorrect" class="flex items-center justify-between px-6 py-6 m-3 bg-gray-100 rounded-lg w-72 hover:bg-gray-200" @click="web3Store.tryConnectToMetamask">
                <p class="font-base">MetaMask</p>
                <img class="h-9 w-9" src="/images/metamask_logo.svg"/>
            </button>
        </template>
        <template v-slot:footer>
            <div v-if="web3Store.showInstallMetaMask"></div>
            <div v-if="!web3Store.isChainIdCorrect"></div>
            <div v-if="!web3Store.showInstallMetaMask && web3Store.isChainIdCorrect" class="flex items-center justify-center">
                <button class="flex items-center justify-center p-3 mt-6 mb-3 text-gray-500 rounded-lg hover:bg-gray-100" @click="web3Store.showInstallMetaMask=true">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
                    </svg>
                    <span class="ml-2">I don't have wallet</span>
                </button>
            </div>
        </template>
    </SimpleModal>
</template>