import { defineStore } from "pinia";
import { ethers } from "ethers";
import { updateWalletAddress } from "@/api/user";
import type {UpdateWalletAddressRequest} from "@/model/reqRes";
import { useAccountStore } from './account';
import { useToast } from 'vue-toastification';
import { addressToShort } from '@/utils/string_utils';

export interface PolygonNetwork {
    chainId: number
    idToHex: string
    chainName: string
    rpcUrls: string[]
    blockExplorerUrls: string[] 
}

export const useWeb3Store = defineStore({
    id: 'web3',
    state: ()=> ({
        //signer: Object,
        showChooseWalletModal: false,
        isChainIdCorrect: true,
        showInstallMetaMask: false,
        useTestNet: false,
        active: false,
        address: '',
        currentNetwork: {
            chainId: 0,
            chainName: ''
        } as PolygonNetwork,
        amoyTestNet: {
            chainId: 80002,
            idToHex: "0x13882",
            chainName: "Amoy",
            rpcUrls: ["https://rpc-amoy.polygon.technology"],
            blockExplorerUrls: ["https://amoy.polygonscan.com"]
        } as PolygonNetwork,
        mainNet: {
            chainId: 137,
            idToHex: '0x89',
            chainName: 'Mainnet',
            rpcUrls: ['https://polygon-rpc.com'],
            blockExplorerUrls: ['https://polygonscan.com']
        } as PolygonNetwork,
    }),
    getters: {
        ethereum(){
            return window.ethereum
        },
        povider(){
            return new ethers.providers.Web3Provider(this.ethereum, "any")
        }
    },
    actions: {
        async updateUserWallet(address:string){
            console.log('update wallet')
            const toast = useToast()
            const accountStore = useAccountStore()
            //if(accountStore.wallet_address.length == 0){
            //    return false
            //}
            if(accountStore.wallet_address.length > 0 && address.length > 0  && accountStore.wallet_address != address){
                toast.error('Your account is already binded with wallet address ' + addressToShort(accountStore.wallet_address) + ', the new connect address ' + addressToShort(address) + ' is not match, please use the binded address to connect')
                return false 
            }
            if(accountStore.wallet_address == address) 
            {
                this.address = address
                return true 
            }
            const req:UpdateWalletAddressRequest = {
                wallet_address: address
            }
            const resp = await updateWalletAddress(req)
            console.log(resp)
            if(resp.code == 200){
                accountStore.wallet_address = address
                accountStore.stored_wallet_address = address
                this.address = address
                return true
            } else {
                toast.error(resp.message)
                return false 
            }
        },
        async checkWalletReady(){
            const accountStore = useAccountStore()
            if(accountStore.token.length == 0){
                window.location.href = '/login'
                return
            }
            if(this.address.length == 0){
                console.log("wallet not ready")
                this.showChooseWalletModal = true 
                return false
            }
            var connected = await this.connectToMetaMask()
            console.log(connected)
            if(!connected){
                return false
            }
            if(!this.checkChainId()){
                this.showChooseWalletModal = true
                return false
            }
            return true;
        },
        async tryConnectToMetamask(){
            if(typeof window.ethereum == 'undefined'){
                this.showInstallMetaMask = true
                return;
            }
            const network = await this.checkConnectNetwork()
            this.isChainIdCorrect = network
            if(!network) {
                return
            }
            const connected = await this.connectToMetaMask()
            if(connected){
                this.showChooseWalletModal = false 
            }
            return
        },
        async checkConnectNetwork(){
            try{
                const provider = this.povider
                const network = await provider.getNetwork()
                console.log("network", network)
                this.currentNetwork.chainId = network.chainId
                this.currentNetwork.chainName = network.name
                return this.checkChainId()
            } catch(err){
                console.log(err)
                return false
            }
        },
        async connectToMetaMask(){
            try{
                const provider = this.povider
                provider.on("network", (newNetwork, oldNetwork) =>{
                    console.log("network changed", newNetwork, oldNetwork)
                    if(oldNetwork != null){
                        console.log("refresh network")
                        window.location.reload()
                    }
                })
                await provider.send("eth_requestAccounts", [])
                const signer = provider.getSigner()
                const address = await signer.getAddress()
                console.log(address)
                const updateSuccess = await this.updateUserWallet(address)
                console.log(updateSuccess)
                if(!updateSuccess) {
                    return false 
                }
                this.active = true
                return true 
            } catch(err){
                console.log(err)
                return false 
            }
        },
        checkChainId() {
            if(this.useTestNet) {
                return this.currentNetwork.chainId === this.amoyTestNet.chainId
            } else {
                return this.currentNetwork.chainId === this.mainNet.chainId
            }
        },
        async switchToPolygonNetwork(){
            try {
                await this.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: this.useTestNet ? this.amoyTestNet.idToHex : this.mainNet.idToHex }],
                })
                //this.active = true
                return true
            } catch (switchError){
                console.log(switchError)
                if(switchError.code === 4902){
                    try {
                        const network = this.useTestNet ? this.amoyTestNet : this.mainNet
                        await this.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                            {
                                chainId: network.idToHex,
                                chainName: network.chainName,
                                nativeCurrency: {
                                    name: "MATIC",
                                    symbol: "MATIC", // 2-6 characters long
                                    decimals: 18,
                                },
                                rpcUrls: network.rpcUrls.slice(),
                                blockExplorerUrls: network.blockExplorerUrls.slice()
                            }
                            ],
                          })
                        return true
                    }catch(addError){
                        console.log(addError)
                    }
                }
                return false
            }
        },
        getNFTListSignHash(id:number, nonce:string){
            return ethers.utils.solidityKeccak256(["uint256", "string"], [id, nonce])
        },
        getNFTBuySignHash(listId:number, orderId:number, nonce:string){
            return ethers.utils.solidityKeccak256(["uint256", "uint256", "string"], [listId, orderId, nonce])
        },
        getNFTRetireSignHash(id:number, tokenId:number, amount:number, nonce:string){
            return ethers.utils.solidityKeccak256(["uint256", "uint256", "uint256", "string"], [id, tokenId, amount, nonce])
        },
        async getSignSignature(messageHash: string){
            if(this.address.length == 0){
                console.log('go to connect meta mask')
                await this.connectToMetaMask()
            }
            var sig = ''
            try{
                const provider = this.povider
                await provider.send("eth_requestAccounts", [])
                var signer = provider.getSigner()
                var sig = await signer.signMessage(ethers.utils.arrayify(messageHash))
                console.log(sig)
            } catch(err){
                console.log(err)
            }
            return sig
        },
        gotoAddress(address:string){
            if(this.currentNetwork.chainId === 80002 || this.useTestNet){
              window.open('https://amoy.polygonscan.com/address/' + address, '_blank')
            } else {
              window.open('https://polygonscan.com/address/' + address, '_blank')
            }
        },  
        gotoTx(tx: string){
            if(this.currentNetwork.chainId === 80002 || this.useTestNet){
              window.open('https://amoy.polygonscan.com/tx/' + tx, '_blank')
            } else {
              window.open('https://polygonscan.com/tx/' + tx, '_blank')
            }
          }
    },
    persist: {
        enabled: true,
        strategies: [
          {
            key: 'web3',
            storage: localStorage,
          }
        ]
    }
});